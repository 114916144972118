import React from 'react'

import Logo from '../../../images/cashNow/logo.png';
import './index.scss';

const Header = () => {
    return (
        <div className="cashnowatm--header">
            <div className="cashnowatm--header__wrapper">
                <div className="cashnowatm--header__logo">
                    <img src={Logo} alt={'TAS Logo'} />
                </div>
            </div>
        </div>
    )
}

export default Header