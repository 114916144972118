import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import "./style.scss"
import successImg from "../../../images/pudra/success.svg"

export const smsErrors = {
  "451": "Невiрний номер телефону",
  "452":
    "Увага! Можливо подати лише 1 заявку протягом 30 днів! Спробуйте пізніше!",
  "453": "Невiрний код",
  "454": minutes => `Помилка: Спробуйте через ${minutes} хвилин(и)`,
}

export const onSendSMS = (phoneNumber, smsContext) => {
  const { startSendSms, sendSMSReq, reSendSmsReq, confirmSmsReq } = smsContext
  const resendBtn = document.createElement("span")
  resendBtn.innerText = "Вiдправити знов"
  resendBtn.className = "resend"
  resendBtn.onclick = reSendSmsReq

  Swal.queue([
    {
      text: "Чи вiрний цей номер телефону?",
      title: phoneNumber,
      showCancelButton: true,
      cancelButtonText: "Нi",
      confirmButtonText: "Так",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: sendSMSReq,
      heightAuto: false,
    },
  ]).then(result => {
    if (result.value) {
      const swalObj = {
        title: `Введiть код пiдтвердження`,
        input: "number",
        confirmButtonText: "Пiдтвердити",
        showCancelButton: true,
        cancelButtonText: "Вiдмiнити",
        allowOutsideClick: false,
        html: resendBtn,
        showLoaderOnConfirm: true,
        preConfirm: confirmSmsReq,
        heightAuto: false,
      }
      if (result.value[0]) {
        swalObj.footer = `Введіть номер з СМС, яке ви отримали або спробуйте ще раз через ${result.value[0]} хвилин(и)`
      }

      Swal.fire(swalObj).then(() => startSendSms(false))
    } else {
      startSendSms(false)
    }
  })
}

export const onStatusError = (
  phoneNumber = null,
  status = null,
  smsContext
) => {
  const { startSendSms } = smsContext

  if (status && phoneNumber) {
    const settings = {
      text: smsErrors[status] || `Сервіс не відповідає спробуйте пізніше`,
      title: phoneNumber,
      icon: "error",
      confirmButtonText: "Зрозуміло",
      allowOutsideClick: false,
      preConfirm: false,
    }

    Swal.fire(settings).then(() => startSendSms(false))
  }
}

export const onSucces = (
  message = `У разі позитивного рішення Вам зателефонують з Банку!`
) => {
  Swal.fire({
    title: "Вашу заявку прийнято!",
    text: message,
    imageUrl: successImg,
    allowOutsideClick: false,
    heightAuto: false,
  })
}

export const onSuccessCashnowAtm = (onClick = null) => {
  Swal.fire({
    title: "Вашу заявку подано!",
    heightAuto: false,
    customClass: {
      container: "thanks-popup",
    },
    html: `
        <p>Чекайте на дзвінок менеджера з Банку!</p>
        <p>
          Ми зателефонуємо Вам протягом 5 хвилин, якщо заявка була подана з 9:00
          до 19:00 або зателефонуємо вже починаючи з 9:00, якщо заявка була
          подана в інший час.
        </p>`,
    imageUrl: successImg,
    allowOutsideClick: false,
    preConfirm: onClick ? onClick : false,
  })
}

export const onFail = () => {
  Swal.fire("Помилка!", `Сервіс не відповідає спробуйте пізніше`, "error")
}
export const onError = () => {
  Swal.fire(
    "",
    `Ваші кредити НЕ підпадають під умови Кредитних канікул`,
    "warning"
  )
}

export const onSuccessCredit = sendOrder => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  })

  swalWithBootstrapButtons
    .fire({
      titleText: "Ваші кредити підпадають під умови Кредитних канікул",
      text: "Оформити заявку щодо участі в пропозиції ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Так, оформити заявку!",
      cancelButtonText: "Hi",
      reverseButtons: true,
    })
    .then(result => {
      console.log("Fetch")
      console.log("result", result)

      if (result.value) {
        sendOrder()
      }
    })
}
